<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <v-footer fixed class="">
      <v-row>
        <v-col class="text-center text-footer" cols="12"
          >© {{ new Date().getFullYear() }}
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100089988214517"
            >車牌快找</a
          >
          | All Rights Reserved.
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="scss">
html {
  overflow-y: auto !important;
}
.text-footer {
  font-size: 14px;
}
main {
  padding-bottom: 50px !important;
}
</style>
