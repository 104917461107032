module.exports = [
  {
    selCarType: "",
    name: "請選擇",
  },
  {
    selCarType: "C",
    name: "汽車",
  },
  {
    selCarType: "M",
    name: "機車",
  },
];
