<template>
  <div>
    <v-container>
      <div>
        <v-tabs centered v-model="tab">
          <v-tab>詳細查詢</v-tab>
          <v-tab>未開牌查詢</v-tab>
          <v-tab>區間號碼</v-tab>
        </v-tabs>
      </div>
      <template v-if="tab == 0">
        <v-row class="mt-2">
          <v-col cols="12" sm="4" md="3" class="pb-0">
            <v-select
              v-model="selected.station"
              :items="stations"
              item-value="name"
              item-text="name"
              label="單位"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="4" md="3" class="pb-0">
            <v-select
              v-model="selected.type"
              :items="carTypes"
              item-value="selPlateType"
              :item-text="
                (item) => {
                  return `${mapType(item.selPlateType)} (${item.selPlateType})`;
                }
              "
              label="車牌別"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="4" md="3" class="pb-0">
            <v-text-field
              label="查詢號碼"
              v-model="keyword"
              outlined
              dense
              persistent-hint
              clearable
              hint="可用 * 代替任意數字"
              @keydown.enter="searchDebounce"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="pt-0" align-self="center">
            <v-btn color="warning" @click="clear">重設</v-btn>
          </v-col>
          <v-col cols="auto" class="pt-0" align-self="center">
            <v-btn color="primary" @click="searchDebounce">搜尋</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="user && !user.active">
          <v-col>
            <v-alert outlined dense type="info" color="primary">
              專業付費版可使用「進牌通知」，預先設定車牌號碼，當該車牌號碼出現於系統中時，會主動發出
              Line 通知，可點選右上角人頭符號購買付費版方案。
            </v-alert>
          </v-col>
        </v-row>
        <div v-if="list.length" class="pb-0 mb-0 mt-4 text-center">
          搜尋結果共 <strong>{{ filterList.length }}</strong> 筆
          <v-row justify="center" class="mt-1 mb-1">
            <v-col cols="6" sm="2">
              <v-select
                v-model="head"
                :items="items"
                label="號碼首數"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2">
              <v-select
                v-model="tail"
                :items="items"
                label="號碼尾數"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2">
              <v-select
                v-model="sum"
                :items="sums"
                label="數字加總"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>

            <v-col cols="6" sm="2">
              <v-select
                v-model="ignores"
                :items="['0', '1', '2', '3', '5', '6', '7', '8', '9']"
                label="排除號碼"
                multiple
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2">
              <v-select
                v-model="feature"
                :items="features"
                label="功能查找"
                hide-details
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="6" sm="2" class="mb-0 pb-0">
              <v-text-field
                label="關鍵字"
                v-model="filterKeyword"
                outlined
                dense
                persistent-hint
                clearable
                hint="可用 * 代替任意數字"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-center pt-0 mt-0">
            <v-col class="pt-0 mt-0">
              <v-btn-toggle v-model="viewMode">
                <v-btn>
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>

                <v-btn>
                  <v-icon>mdi-view-grid-outline</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <v-data-table
            v-if="viewMode == 0"
            :headers="headers"
            :items="filterList"
            no-data-text="無資料"
            :mobile-breakpoint="0"
            multi-sort
            :footer-props="{
              itemsPerPageText: '每頁幾筆',
              itemsPerPageOptions: [15, 30, -1],
            }"
            class="elevation-1 mt-4 mb-10"
          >
            <template v-slot:item.page="{ item }"> P.{{ item.page }} </template>
            <template v-slot:item.score.score="{ item }">
              <v-tooltip right v-if="item.score && item.score.score">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.score.score }}
                  </span>
                </template>
                <ul>
                  <li v-for="li of item.score.list">{{ li }}</li>
                </ul>
              </v-tooltip>
              <span v-else>-</span>
            </template>
          </v-data-table>
          <v-row v-if="viewMode == 1">
            <v-col class="d-flex justify-center flex-wrap pb-10" id="result">
              <div
                class="ma-2 tag"
                large
                v-for="row of filterList"
                :key="row.tag"
              >
                <div>{{ row.tag }}</div>
                <div class="station">{{ row.station }}</div>
                <div class="page">P.{{ row.page }}</div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="!filterList.length">
          <v-col>
            <v-alert outlined dense type="info" color="primary">
              查無符合條件的車牌
            </v-alert>
          </v-col>
        </div>
      </template>
      <template v-if="tab == 1">
        <v-row class="mt-2" justify="center">
          <v-col cols="6" sm="3" md="2" class="pb-0">
            <v-select
              v-model="quick.type"
              :items="carTypes"
              item-value="selPlateType"
              :item-text="
                (item) => {
                  return `${mapType(item.selPlateType)} (${item.selPlateType})`;
                }
              "
              label="車牌別"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="6" sm="3" md="2" class="pb-0">
            <v-text-field
              label="查詢號碼"
              v-model="quick.tag"
              outlined
              dense
              persistent-hint
              clearable
              hint="限輸入四位數字號碼"
              @keydown.enter="quickSearchDebounce"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="pt-0" align-self="center">
            <v-btn color="primary" @click="quickSearchDebounce">搜尋</v-btn>
          </v-col>
        </v-row>
        <div class="mt-6" v-if="quickSearchResult">
          <div class="text-h6 text-center">
            目前可選號牌
          </div>
          <v-row v-if="quickSearchResult.tags.length">
            <v-col class="d-flex justify-center flex-wrap pb-10" id="result">
              <div
                class="ma-2 tag"
                large
                v-for="row of quickSearchResult.tags"
                :key="row.tag"
              >
                <div>{{ row.tag }}</div>
                <div class="station">{{ row.station }}</div>
                <div class="page">P.{{ row.page }}</div>
              </div>
            </v-col>
          </v-row>
          <div v-else class="text-center">
            目前無任何選號車牌，建議可以選擇標牌或等候「{{
              closest.station
            }}」開號，目前最新開號 {{ closest.tag }}。
          </div>
          <div class="text-h6 text-center mt-10">
            目前可標號牌
          </div>
          <div class="mt-4">
            <v-data-table
              :headers="quickSearchBidHeaders"
              :items="quickSearchResult.bids"
              no-data-text="無資料"
              :mobile-breakpoint="0"
              :footer-props="{
                itemsPerPageText: '每頁幾筆',
                itemsPerPageOptions: [15, 30, -1],
              }"
              class="elevation-1"
            ></v-data-table>
          </div>
        </div>
      </template>
      <template v-if="tab == 2">
        <v-row class="mt-2" justify="center" align="center">
          <v-col cols="6" sm="3" md="2">
            <v-select
              v-model="newest.type"
              :items="carTypes"
              item-value="selPlateType"
              :item-text="
                (item) => {
                  return `${mapType(item.selPlateType)} (${item.selPlateType})`;
                }
              "
              label="車牌別"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn color="primary" @click="newestSearchDebounce">搜尋</v-btn>
          </v-col>
        </v-row>
        <div class="mt-6 d-flex justify-center" v-if="newestSearchResult">
          <v-sheet color="white" elevation="2" width="400">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      單位
                    </th>
                    <th class="text-left">
                      目前號碼
                    </th>
                    <th class="text-left">
                      最新號碼
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in newestSearchResult">
                    <td>{{ row.station }}</td>
                    <td>{{ row.firstTag }}</td>
                    <td>{{ row.tag }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-sheet>
        </div>
      </template>
    </v-container>
    <div class="go-mdvis">
      <v-btn class="mx-2" fab dark large color="primary" @click="goMdvis">
        <v-icon large dark>
          mdi-warehouse
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import scoreRules from "@/libs/scoreRules.js";
import carTypes from "@/libs/carTypes.js";
import stations from "@/libs/stations.js";
import ls from "local-storage";
import axios from "axios";
import config from "@/libs/config.js";
import _ from "lodash";

const typeMapping = {
  1: "自用小客貨車",
  h: "電動租賃車",
  g: "電動自小客",
  // i: "電動小營客車",
  G: "紅牌重機車",
  F: "黃牌重機車",
  P: "電動普通重型機車",
  H: "普通重型機車",
  2: "租賃小客貨車",
  3: "營業小客車",
  6: "自用大貨車",
  Q: "電動普通輕型機車",
};
export default {
  name: "Search",
  props: ["user", "auth"],
  data: () => ({
    tab: 0,
    carTypes,
    stations: ["全選", ...stations],
    selected: {
      station: "全選",
      type: "全選",
    },
    quick: {
      type: "",
      tag: "",
    },
    newest: {
      type: "",
    },
    quickSearchResult: null,
    newestSearchResult: null,
    list: [],
    viewMode: 0,
    keyword: "",
    feature: "",
    filterKeyword: "",
    head: "",
    tail: "",
    ignores: [],
    sum: null,
    headers: [
      { text: "單位", value: "station" },
      { text: "頁面", value: "page" },
      { text: "車牌", value: "tag" },
      {
        text: "分數(測試)",
        value: "score.score",
        default: false,
        align: "center",
      },
    ],
    quickSearchBidHeaders: [
      { text: "單位", value: "station", default: true },
      { text: "車牌別", value: "type", default: true },
      { text: "牌號", value: "tag", default: true, width: "114px" },
      { text: "起標時間", value: "startTime", default: true },
      { text: "目前出價", value: "bidPrice", default: false },
      { text: "出價次數", value: "bidTime", default: false },
      { text: "決標時間", value: "endTime", default: true },
    ],
    features: [
      { text: "無", value: "" },
      { divider: true },
      "流標牌",
      { divider: true },
      "數字漸大",
      "數字漸小",
      { divider: true },
      "雙同數",
      "三同數",
      { divider: true },
      "疊數",
      "雙疊數",
      "三疊數",
      "四疊數",
      { divider: true },
      "總和 21",
      "總和 24",
      "總和 25",
      "總和 29",
      "總和 31",
      "總和 32",
      "總和 33",
      "總和 35",
    ],
    items: [
      { text: "不限", value: "" },
      "0",
      "1",
      "2",
      "3",
      "5",
      "6",
      "7",
      "8",
      "9",
    ],
  }),
  computed: {
    sums() {
      let arr = _.range(1, 37);
      arr.unshift({
        text: "不限",
        value: null,
      });
      return arr;
    },
    closest() {
      if (!this.quickSearchResult) return null;
      let newest = this.quickSearchResult.newest.map((o) => {
        let tag = parseInt(o.tag.split("-")[1]);
        let distance = parseInt(this.quick.tag) - tag;
        distance = distance > 0 ? distance : distance + 9999;
        return { ...o, distance };
      });
      return _.minBy(newest, "distance");
    },
    filterList() {
      let tags = this.list;
      if (this.filterKeyword) {
        let regex = new RegExp(this.filterKeyword.replace(/\*/g, "[0-9]"), "g");
        tags = tags.filter((row) => regex.test(row.tag));
      }
      if (this.head) {
        tags = tags.filter((row) => row.tag?.split("-")[1][0] == this.head);
      }
      if (this.tail) {
        tags = tags.filter((row) => row.tag?.split("-")[1][3] == this.tail);
      }
      if (this.sum) {
        tags = tags.filter(
          (row) =>
            _.sum(
              row.tag
                ?.split("-")[1]
                .split("")
                .map((r) => r * 1)
            ) == this.sum
        );
      }
      if (this.ignores.length) {
        this.ignores.forEach((ignore) => {
          tags = tags.filter((row) => !row.tag?.split("").includes(ignore));
        });
      }
      if (this.feature) {
        // if (this.feature == "已標記") {
        //   tags = tags.filter((row) => this.pick?.includes(row.tag));
        // }
        tags = tags.filter((row) => row.tag);
        if (this.feature == "流標牌") {
          tags = tags.filter((row) => row.page == "流標");
        }
        if (this.feature == "數字漸大") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] <= number[1] &&
              number[1] <= number[2] &&
              number[2] <= number[3]
            );
          });
        }
        if (this.feature == "數字漸小") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] >= number[1] &&
              number[1] >= number[2] &&
              number[2] >= number[3]
            );
          });
        }
        if (this.feature == "疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] == number[1] ||
              number[1] == number[2] ||
              number[2] == number[3]
            );
          });
        }
        if (this.feature == "雙疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return number[0] == number[1] && number[2] == number[3];
          });
        }
        if (this.feature == "三疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[1] == number[2]) ||
              (number[1] == number[2] && number[2] == number[3])
            );
          });
        }
        if (this.feature.includes("總和")) {
          let sum = this.feature.split(" ")[1];
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] * 1 + number[1] * 1 + number[2] * 1 + number[3] * 1 ==
              sum * 1
            );
          });
        }
        if (this.feature == "四疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] == number[1] &&
              number[1] == number[2] &&
              number[2] == number[3]
            );
          });
        }
        if (this.feature == "雙同數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[2] == number[3]) ||
              (number[0] == number[2] && number[1] == number[3])
            );
          });
        }
        if (this.feature == "三同數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[1] == number[2]) ||
              (number[1] == number[2] && number[2] == number[3]) ||
              (number[0] == number[1] && number[1] == number[3]) ||
              (number[0] == number[2] && number[2] == number[3])
            );
          });
        }
      }
      return tags;
    },
  },
  async created() {
    this.selected.station = ls("VIPstation") || "全選";
    this.selected.type = ls("VIPcarType") || "1";
    this.quick.type = ls("VIPQuickType") || "1";
    this.newest.type = ls("VIPNewestType") || "1";
    this.tab = ls("VIPSearchtab") || 0;
    this.searchDebounce = _.debounce(this.search, 500);
    this.quickSearchDebounce = _.debounce(this.quickSearch, 500);
    this.newestSearchDebounce = _.debounce(this.newestSearch, 500);
  },

  methods: {
    getScore(tag) {
      let number = tag.split("-")[1];
      let score = 0;
      let list = [];

      scoreRules.forEach((rule) => {
        if (number && rule.method(number)) {
          score += rule.score;
          list.push(rule.label(number));
        }
      });
      if (score < 0) score = 0;
      return { score, list };
    },
    searchDebounce() {},
    quickSearchDebounce() {},
    newestSearchDebounce() {},
    mapType(type) {
      return typeMapping[type];
    },
    clear() {
      this.list = [];
      this.keyword = "";
    },
    async newestSearch() {
      if (!!!this.newest.type) {
        this.$toast.error("請選擇車牌別！");
        return false;
      }
      this.$vloading.show();
      try {
        await this.auth.currentUser.getIdToken(true);
        this.newestSearchResult = (
          await axios.post(`${config.API_URL}/searchNewest`, this.newest)
        ).data;
        // console.log(this.newestSearchResult);
      } catch (error) {
        this.$toast.error("登入 Token 過期，請重新整理頁面！");
      }
      this.$vloading.hide();
    },
    async quickSearch() {
      if (!!!this.quick.type) {
        this.$toast.error("請選擇車牌別！");
        return false;
      }
      if (!/[0-9]{4}/.test(this.quick.tag)) {
        this.$toast.error("限輸入四位數字號碼！");
        return false;
      }
      this.$vloading.show();
      try {
        await this.auth.currentUser.getIdToken(true);
        this.quickSearchResult = (
          await axios.post(`${config.API_URL}/searchAll`, this.quick)
        ).data;
      } catch (error) {
        this.$toast.error("登入 Token 過期，請重新整理頁面！");
      }
      this.$vloading.hide();
    },
    async search() {
      if (!!!this.selected.station) {
        this.$toast.error("請選擇單位");
        return false;
      }
      this.$vloading.show();
      try {
        await this.auth.currentUser.getIdToken(true);
        let list = (
          await axios.post(`${config.API_URL}/search`, {
            selected: this.selected,
            keyword: this.keyword,
            token: this.user.accessToken,
          })
        ).data;
        if (!list) {
          this.$toast.error("查無車牌資訊！");
        }
        list = list.map((row) => ({
          ...row,
          score: this.getScore(row.tag),
        }));
        this.list = list;
      } catch (error) {
        this.$toast.error("登入 Token 過期，請重新整理頁面！");
      }
      this.$vloading.hide();
    },
    goMdvis() {
      window.open(
        "https://www.mvdis.gov.tw/m3-emv-plate/webpickno/queryPickNo",
        "_blank"
      );
    },
  },
  watch: {
    tab(value) {
      ls("VIPSearchtab", value);
      let event_category = "";
      switch (value) {
        case 0:
          event_category = "詳細查詢";
          break;
        case 1:
          event_category = "未開牌查詢";
          break;
        case 2:
          event_category = "區間號碼";
          break;
      }
      gtag("event", "副目錄", {
        event_category,
        // event_label,
        value: "1",
      });
    },
    "selected.station"(station) {
      ls("VIPstation", station);
    },
    "selected.type"(value) {
      ls("VIPcarType", value);
    },
    "quick.type"(value) {
      ls("VIPQuickType", value);
    },
    "newest.type"(value) {
      ls("VIPNewestType", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
::v-deep .text-tag {
  font-size: 20px !important;
  font-weight: bold;
}
.text-small {
  font-size: 12px !important;
}
.tag {
  background: #fff;
  border: 2px solid rgb(135, 135, 135);
  border-radius: 10px;
  padding: 10px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  width: 140px;
  .page {
    font-size: 12px;
    font-weight: 400;
  }
  .station {
    font-size: 14px;
    font-weight: 400;
  }
}
.go-mdvis {
  position: fixed;
  bottom: 40px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
}
</style>
