<template>
  <v-container class="mt-8 mb-8">
    <v-card class="px-4">
      <v-card-title>自動購買車牌程式使用說明</v-card-title>
      <v-card-text class="px-8">
        <ol class="text-subtitle-1">
          <li>下載主程式，並解壓縮。</li>
          <v-btn
            color="info"
            class="mb-2"
            href="https://drive.google.com/file/d/1RBPrmB2nhA1Bo70pnZK2CeHjB9k20768/view?usp=sharing"
            target="_blank"
            >下載主程式</v-btn
          >

          <li>
            下載更新檔，置於主程式資料夾內，會取代原本執行檔。
          </li>
          <v-btn
            color="info"
            class="mb-2"
            :href="'https://car-tags.com/app-win.exe?random=' + random"
            download
            >下載 Windows 更新檔</v-btn
          >
          <li>
            填寫下方表單，填完後按產出設定檔按鈕，將下載的設定檔置於主程式資料夾內，會取代原本設定檔。
          </li>
          <li>
            windows 系統執行「app-win.exe」，蘋果 MAC
            系統執行「app-macos」，即可啟動執行。
          </li>
          <li>
            若有疑問或問題回報，請聯繫作者
            <strong
              ><a target="_blank" href="https://www.facebook.com/chanwei.wu"
                >Howard</a
              ></strong
            >。
          </li>
        </ol>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>執行參數</v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <v-text-field
            v-model="config.waitPeriod"
            label="選號頁面重整時間(毫秒)"
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>帳號設定</v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <v-text-field
            v-model="config.login.email"
            label="監理站登入帳號"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="config.login.password"
            label="監理站登入密碼"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
            v-model="config.pin"
            label="自然人憑證 pin 碼"
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>自動驗證</v-card-title>

      <v-card-text class="px-8">
        <v-row>
          <v-alert type="info" elevation="2" dense width="100%">
            非必要，可以手動輸入驗證碼。但如果要使用自動輸入驗證碼，會用
            Azcaptcha 的服務，需要註冊 Azcaptcha 帳號並且儲值，每個自動驗證大約
            0.012 台幣，系統自動輸入驗證碼約 5 秒。
          </v-alert>
        </v-row>

        <v-row>
          <v-text-field
            v-model="config.azcaptchaKey"
            label="驗證碼 API key"
            hint="先註冊 https://azcaptcha.com 帳號，儲值後可複製 API key 填入。"
            persistent-hint
          ></v-text-field>
        </v-row>
        <v-row>
          <v-checkbox
            class="mr-4"
            v-model="config.autoSolve[0]"
            :value="true"
            label="自動驗證「選監理站」的驗證碼"
            type="checkbox"
            hide-details
          ></v-checkbox>
        </v-row>
        <v-row>
          <v-checkbox
            v-model="config.autoSolve[1]"
            :value="true"
            label="自動驗證「購買車牌」的驗證碼"
            type="checkbox"
          ></v-checkbox>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>監理站與車牌</v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <v-select
            :items="allStations"
            v-model="config.selStationCode"
            item-text="name"
            item-value="selStationCode"
            label="監理站"
          ></v-select>
        </v-row>
        <v-row>
          <v-select
            :items="allCarTypes"
            v-model="config.selCarType"
            item-text="name"
            item-value="selCarType"
            label="車種別"
          ></v-select>
        </v-row>
        <v-row>
          <v-select
            :items="allEnergyType"
            v-model="config.selEnergyType"
            item-text="name"
            item-value="selEnergyType"
            label="能源別"
          ></v-select>
        </v-row>
        <v-row>
          <v-select
            :items="allPlateTypes"
            v-model="config.selPlateType"
            item-text="name"
            item-value="selPlateType"
            label="車牌別"
          ></v-select>
        </v-row>
        <v-row>
          <v-text-field
            v-model="config.queryNo"
            label="要購買的車牌號碼"
            hint="例：ABC-1234"
            persistent-hint
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>車主資訊</v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <v-select
            :items="optionOwnerType"
            v-model="config.ownerType"
            label="領牌車主別"
          ></v-select>
        </v-row>
        <v-row>
          <v-select
            :items="optionProcessType"
            v-model="config.processType"
            label="辦理別"
          ></v-select>
        </v-row>
        <v-row>
          <div class="text-subtitle-1">車輛曾經領過車牌</div>
        </v-row>
        <v-row>
          <v-radio-group v-model="config.hasGetPlate" row class="mt-0">
            <v-radio label="是" value="Y"></v-radio>
            <v-radio label="否" value="N"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <div class="text-subtitle-1">戶籍郵遞區號(須與牌照登記書一致)</div>
        </v-row>
        <v-row>
          <v-col class="pl-0">
            <v-select
              v-model="config.countyText"
              :items="cityOption"
              label="縣市"
              dense
            ></v-select>
          </v-col>
          <v-col class="pr-0">
            <v-select
              v-model="config.area"
              :items="areaOption"
              label="鄉鎮市區"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="config.hasGetPlate == 'Y'">
          <v-text-field
            v-model="config.orignPlateNo"
            label="原領車號"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-radio-group v-model="config.seriesNoType" row class="mt-0">
            <v-radio label="車身號碼" value="1"></v-radio>
            <v-radio label="引擎號碼" value="2"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row>
          <v-text-field
            v-model="config.carSeriesNo"
            label="車身號碼或引勤號碼(須與牌照登記書一致)"
            dense
          ></v-text-field>
        </v-row>
        <v-row v-if="config.processType == '2'">
          <v-text-field
            v-model="config.ownerName"
            label="領牌車主名稱"
          ></v-text-field>
        </v-row>
        <v-row v-if="config.processType == '2'">
          <v-text-field
            v-model="config.ownerUid"
            label="領牌車主證號"
          ></v-text-field>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-4 px-4">
      <v-card-title>繳納費用</v-card-title>
      <v-card-text class="px-8">
        <v-row>
          <div class="text-subtitle-1">選擇付款方式</div>
        </v-row>
        <v-row>
          <v-radio-group v-model="config.payType" row class="mt-0">
            <v-radio label="信用卡繳費" value="0"></v-radio>
            <v-radio label="金融活期帳戶" value="1"></v-radio>
          </v-radio-group>
        </v-row>
        <template v-if="config.payType == '0'">
          <v-row>
            <div class="text-subtitle-1">信用卡號</div>
          </v-row>
          <v-row dense>
            <v-col class="pl-0">
              <v-text-field
                v-model="config.cardNo1"
                dense
                hide-details
                append-outer-icon="mdi-window-minimize"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="config.cardNo2"
                dense
                hide-details
                append-outer-icon="mdi-window-minimize"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="config.cardNo3"
                dense
                hide-details
                append-outer-icon="mdi-window-minimize"
              ></v-text-field>
            </v-col>
            <v-col class="pr-0">
              <v-text-field
                v-model="config.cardNo4"
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <div class="text-subtitle-1">有效年月</div>
          </v-row>
          <v-row>
            <v-col class="pl-0">
              <v-text-field
                suffix="月"
                v-model="config.cardMonth"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col class="pr-0">
              <v-text-field
                suffix="年"
                v-model="config.cardYear"
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="config.payType == '1'">
          <v-row>
            <v-select
              v-model="config.bankId"
              :items="optionBanks"
              label="選擇銀行"
            ></v-select>
          </v-row>
          <v-row>
            <v-text-field
              v-model="config.accountNo"
              label="金融卡卡號"
            ></v-text-field>
          </v-row>
        </template>
        <v-row>
          <v-radio-group v-model="config.purchase" class="mt-0">
            <v-radio label="僅測試，不執行付款" :value="false"></v-radio>
            <v-radio label="執行付款" :value="true"></v-radio>
          </v-radio-group>
        </v-row>
      </v-card-text>
    </v-card>
    <v-btn
      class="mt-4"
      :color="config.purchase ? 'error' : 'primary'"
      block
      large
      @click="download()"
      >產出{{ config.purchase ? "付款" : "測試" }}設定檔</v-btn
    >
    <v-btn class="mt-4" color="warning" block large @click="load()"
      >上傳設定檔</v-btn
    >
    <input
      ref="uploader"
      class="d-none"
      type="file"
      accept="application/JSON"
      @change="onFileChanged"
    />
  </v-container>
</template>

<script>
import allStations from "@/libs/allStations";
import allCarTypes from "@/libs/allCarTypes";
import allEnergyType from "@/libs/allEnergyType";
import allPlateTypes from "@/libs/allPlateTypes";
import optionBanks from "@/libs/optionBanks";
import citys from "@/libs/citys.json";
import ls from "local-storage";

export default {
  name: "Configs",
  data: () => ({
    allStations,
    allCarTypes,
    allEnergyType,
    allPlateTypes,
    config: {
      autoSolve: [false, false],
      azcaptchaKey: "",
      login: {
        email: "",
        password: "",
      },
      selDeptCode: "",
      selStationCode: "",
      selCarType: "",
      selEnergyType: "",
      queryNo: "",
      ownerType: "",
      processType: "",
      hasGetPlate: "",
      countyText: "",
      area: "",
      orignPlateNo: "",
      seriesNoType: "",
      carSeriesNo: "",
      ownerName: "",
      ownerUid: "",
      payType: "",
      cardNo1: "",
      cardNo2: "",
      cardNo3: "",
      cardNo4: "",
      cardMonth: "",
      cardYear: "",
      bankId: "",
      accountNo: "",
      pin: "",
      purchase: false,
      waitPeriod: 1000,
    },
    optionOwnerType: [
      { text: "請選擇", value: "0", disabled: true },
      { text: "自然人", value: "1" },
      { text: "法人", value: "2" },
    ],
    optionProcessType: [
      { text: "請選擇", value: "0", disabled: true },
      { text: "本人", value: "1" },
      { text: "代辦", value: "2" },
    ],
    optionBanks,
    uploadConfig: null,
    areaOption: [{ text: "請選擇", value: "", disabled: true }],
  }),
  computed: {
    random() {
      return Math.ceil(Math.random() * 1000000);
    },
    cityOption() {
      let options = [
        { text: "請選擇", value: "", disabled: true },
        ...citys.map((city) => {
          return {
            text: city.CityName,
            value: city.CityName,
          };
        }),
      ];
      return options;
    },
  },
  created() {
    let config = ls.get("config");
    if (config) this.config = config;
  },
  methods: {
    load() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let reader = new FileReader();
      reader.onload = this.onReaderLoad;
      reader.readAsText(e.target.files[0]);
    },
    onReaderLoad(e) {
      this.config = JSON.parse(e.target.result);
    },
    download() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.config));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "configs.json");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  },
  watch: {
    "config.countyText"(countyText) {
      let areas = citys.find((city) => {
        console.log(city);
        return city.CityName == countyText;
      }).AreaList;
      let options = [
        { text: "請選擇", value: "", disabled: true },
        ...areas.map((area) => ({
          text: area.AreaName,
          value: area.AreaName,
        })),
      ];
      this.areaOption = options;
    },
    "config.selStationCode"(value) {
      this.config.selDeptCode = value.charAt();
    },
    config: {
      handler() {
        ls.set("config", this.config);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
