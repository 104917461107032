module.exports = [
  {
    selPlateType: "",
    name: "請選擇",
  },
  {
    selPlateType: "1",
    suffix: "", // 一般自小客車
    name: "自用小客貨車",
  },
  {
    selPlateType: "2",
    suffix: "_R", // 租賃小客貨車
    name: "租賃小客貨車",
  },
  {
    selPlateType: "g",
    suffix: "_E", // 電動自小客車
    name: "電動自小客",
  },
  {
    selPlateType: "h",
    suffix: "_T", // 電動租賃車
    name: "電動租賃車",
  },
  {
    selPlateType: "H",
    suffix: "_H", // 白牌機車
    name: "普通重型機車",
  },
  {
    selPlateType: "G",
    suffix: "_M", // 紅牌重機車
    name: "紅牌重機車",
  },
  {
    selPlateType: "F",
    suffix: "_Y", // 黃牌重機車
    name: "黃牌重機車",
  },
  {
    selPlateType: "P",
    suffix: "_P", // 電動機車
    name: "電動普通重型機車",
  },
  {
    selPlateType: "Q",
    suffix: "_Q", // 電動輕型機車
    name: "電動普通輕型機車",
  },
  {
    selPlateType: "6",
    suffix: "_6", // 自用大貨車
    name: "自用大貨車",
  },
  {
    selPlateType: "3",
    suffix: "_3", // 營業小客貨車
    name: "營業小客貨車",
  },
  // {
  //   selPlateType: "i",
  //   suffix: "_I", // 電動小營客車
  //   name: "電動小營客車",
  // },
];
