<template>
  <div>
    <v-container>
      <div>
        <v-tabs centered v-model="tab">
          <v-tab>標牌公告</v-tab>
          <v-tab>現在競標牌</v-tab>
          <v-tab>標售紀錄</v-tab>
        </v-tabs>
      </div>
      <template v-if="tab == 2">
        <v-row class="mt-4" justify="center" dense>
          <v-col cols="auto">
            <v-text-field
              label="查詢號碼"
              v-model="tag"
              outlined
              dense
              persistent-hint
              clearable
              hint="需輸入完整車牌"
              @keydown.enter="searchTag"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="searchTag" class="mr-2">查詢</v-btn>
            <v-btn color="warning" @click="clear">清除</v-btn>
          </v-col>
        </v-row>
        <v-divider class="mt-4 pb-4"></v-divider>
        <template v-if="searchResult">
          <v-row dense>
            <template v-for="(value, key) in searchResult">
              <v-col cols="6" class="text-right font-weight-bold">
                {{ key }}：
              </v-col>
              <v-col cols="6" class="font-weight-regular">
                {{ value }}
              </v-col>
            </template>
          </v-row>
        </template>
        <template v-else> <div class="text-center">查無資料</div> </template>
      </template>
      <template v-else>
        <v-row class="mt-2">
          <v-col cols="12" sm="3" md="3" class="pb-0">
            <v-select
              v-model="station"
              :items="stations"
              label="單位"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="12" sm="3" md="3" class="pb-0">
            <v-select
              v-model="carType"
              :items="carTypes"
              label="車牌別"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>

          <v-col cols="6" sm="3" md="3" class="pb-0">
            <v-text-field
              label="查詢號碼"
              v-model="keyword"
              outlined
              dense
              persistent-hint
              clearable
              hint="可用 * 代替任意數字"
              @keydown.enter="searchDebounce"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3" class="pb-0" v-if="tab == 1">
            <v-checkbox
              class="mt-1"
              v-model="haveBid"
              label="有出價"
              type="checkbox"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="load()">
              更新
            </v-btn>
          </v-col>
          <v-col>
            <div style="font-size: 8px" class="text-right">最後更新時間</div>
            <div style="font-size: 8px" class="text-right">
              {{ patchTime }}
            </div>
          </v-col>
        </v-row>
        <div class="mt-4">
          <v-data-table
            :headers="_headers"
            :items="_rows"
            :loading="loading"
            :item-class="rowClass"
            no-data-text="無資料"
            :mobile-breakpoint="0"
            multi-sort
            :footer-props="{
              itemsPerPageText: '每頁幾筆',
              itemsPerPageOptions: [15, 30, -1],
            }"
            class="elevation-1"
          >
            <template v-slot:item.score.score="{ item }">
              <v-tooltip right v-if="item.score && item.score.score">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.score.score }}
                  </span>
                </template>
                <ul>
                  <li v-for="li of item.score.list">{{ li }}</li>
                </ul>
              </v-tooltip>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import scoreRules from "@/libs/scoreRules.js";
import axios from "axios";
import dayjs from "dayjs";
import ls from "local-storage";
import config from "@/libs/config.js";
import allStations from "@/libs/allStations.js";
const tabMapping = ["announce", "bidding", "failure"];
export default {
  name: "Bid",
  props: ["user", "auth", "userActive"],
  data: () => ({
    tab: 0,
    rows: [],
    loading: false,
    station: "全選",
    patchTime: "-",
    tag: "",
    searchResult: {},
    haveBid: true,
    carTypes: [
      "全部",
      "不分車牌",
      "自用小客貨車",
      "租賃小客貨車",
      "大型重機車(550cc以上)",
      "大型重機車(550cc以下)",
      "普通重型機車",
      "普通輕型機車",
      "電動550cc以下重機",
      "電動550cc以上重機",
      "電動普通重型機車",
      "電動普通輕型機車",
      "電動小型輕型機車",
      "營業小客車",
      "營業小客車(民營市區客運)",
      "營業小貨車",
      "自用大客車",
      "自用大貨車",
      "營業大客車",
      "營業大貨車",
      "營業貨櫃曳引",
      "遊覽大客車",
      "交通車",
      "租賃大客貨車",
      "身障自用小客貨車",
      "自用拖車",
      "營業拖車",
      "電動自小客",
      "電動租賃車",
      // "電動小營客車",
      "電動大營客車",
      "微型電動二輪車",
    ],
    carType: "全選",
    keyword: "",
    headers: [
      { text: "單位", value: "station", default: true },
      { text: "車牌別", value: "type", default: true },
      { text: "牌號", value: "tag", default: true, width: "114px" },
      {
        text: "分數(測試)",
        value: "score.score",
        default: false,
        align: "center",
      },
      { text: "起標時間", value: "startTime", default: true },
      { text: "目前出價", value: "bidPrice", default: false },
      { text: "出價次數", value: "bidTime", default: false },
      { text: "決標時間", value: "endTime", default: true },
    ],
  }),
  async created() {
    this.station = ls("VIPBidstation") || "全部";
    this.carType = ls("VIPBidcarType") || "全部";
    this.tab = ls("VIPBidtab") || 0;
    await this.load();
  },
  computed: {
    _rows() {
      let rows = this.rows;
      rows = rows.filter((row) => {
        if (this.haveBid) {
          return row.bidPrice != "-";
        } else {
          return row.bidPrice == "-";
        }
      });
      if (this.station != "全部") {
        rows = rows.filter((row) => row.station == this.station);
      }
      if (this.carType != "全部") {
        rows = rows.filter((row) => row.type == this.carType);
      }
      if (this.keyword) {
        let reg;
        switch (true) {
          case this.keyword.length < 4:
          case this.keyword.includes("-"):
            reg = new RegExp(`.?${this.keyword.replace(/\*/g, ".")}.?`);
            break;
          case this.keyword.length == 4:
            reg = new RegExp(`-${this.keyword.replace(/\*/g, ".")}$`);
            break;
        }
        rows = rows.filter((row) => reg.test(row.tag));
      }
      return rows;
    },
    stations() {
      let stations = allStations.map((row) => row.name);
      stations.shift();
      stations.unshift("全部");
      return stations;
    },
    _headers() {
      return this.tab == 1
        ? this.headers
        : this.headers.filter((row) => row.default);
    },
  },
  methods: {
    getScore(tag) {
      let number = tag.split("-")[1];
      let score = 0;
      let list = [];
      try {
        scoreRules.forEach((rule) => {
          if (number && rule.method(number)) {
            score += rule.score;
            list.push(rule.label(number));
          }
        });
      } catch (error) {
        console.log(error);
        console.log(number);
      }

      if (score < 0) score = 0;
      return { score, list };
    },
    clear() {
      this.tag = "";
      this.searchResult = {};
    },
    async searchTag() {
      try {
        this.tag = this.tag.toUpperCase();
        if (this.userActive) {
          if (!/[A-Z]{3}-[0-9]{4}/.test(this.tag)) {
            this.$toast.error("查詢號碼錯誤，請輸入完整車牌！");
            return false;
          }
          this.$vloading.show();
          await this.auth.currentUser.getIdToken(true);
          let res = await axios.post(`${config.API_URL}/history`, {
            tag: this.tag,
            token: this.user.accessToken,
          });

          this.searchResult = res.data;
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("登入 Token 過期，請重新整理頁面！");
      }
      this.$vloading.hide();
    },
    async load() {
      if (this.tab == 2) return;
      try {
        if (this.userActive) {
          this.$vloading.show();
          await this.auth.currentUser.getIdToken(true);
          let res = await axios.post(`${config.API_URL}/bid`, {
            type: tabMapping[this.tab],
            token: this.user.accessToken,
          });

          let rows = res.data;

          if (this.tab == 0) {
            let groups = _.groupBy(
              rows,
              (row) =>
                `${row.station}|${row.type}|${row.startTime}|${row.range}|${
                  row.tag.includes("~") ? row.tag : ""
                }`
            );

            rows = _.map(groups, (group) => {
              return {
                station: group[0].station,
                type: group[0].type,
                startTime: group[0].startTime,
                endTime: group[0].endTime,
                tag: group.map((r) => r.tag).join(" "),
                muti: group.length > 1,
              };
            });
            rows = rows.filter(
              (row) =>
                !(
                  (dayjs() > dayjs(row.startTime) || !row.startTime) &&
                  !row.tag.includes("~")
                )
            );
          } else {
            rows = rows.filter((row) => !row.range);
          }

          if (this.tab == 1) {
            let baseRows = res.data.filter((row) => row.range);
            let getRange = (range) => {
              let start = range.split("~")[0].trim();
              let end = range.split("~")[1].trim();
              let startNum = parseInt(start.split("-")[1]);
              let endNum = parseInt(end.split("-")[1]);
              let flat = [];
              for (let i = startNum; i <= endNum; i++) {
                flat.push(
                  `${start.split("-")[0]}-${i.toString().padStart(4, "0")}`
                );
              }
              return flat;
            };
            let rangeRows = baseRows.map((row) => {
              let range = getRange(row.tag);
              return range.map((tag) => {
                return {
                  station: row.station,
                  type: row.type,
                  startTime: row.startTime,
                  endTime: row.endTime,
                  tag,
                  bidPrice: "-",
                  bidTime: "-",
                };
              });
            });
            rows = _.uniqBy([...rows, ...rangeRows.flat()], "tag");
            rows = rows.map((row) => ({
              ...row,
              score: this.getScore(row.tag),
            }));
          }

          this.rows = rows.map((row) => {
            row.startTime = row.startTime?.replace(/:00$/, "") || "";
            row.endTime = row.endTime?.replace(/:00$/, "") || "";
            return row;
          });
          this.patchTime = dayjs().format("MM/DD HH:mm:ss");
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("登入 Token 過期，請重新整理頁面！");
      }
      this.$vloading.hide();
    },
    rowClass(item) {
      let classes = [];
      if (item.tag.includes("~")) {
        classes.push("range");
      }
      if (
        this.tab == 0 &&
        dayjs() >= dayjs(item.startTime) &&
        item.tag.includes("~")
      ) {
        classes.push("ing");
      }
      return classes.join(" ");
    },
  },
  watch: {
    tab(value) {
      this.load();
      ls("VIPBidtab", value);

      let event_category = "";
      switch (value) {
        case 0:
          event_category = "標牌公告";
          break;
        case 1:
          event_category = "現在競標牌";
          break;
        case 2:
          event_category = "標售紀錄";
          break;
      }
      gtag("event", "副目錄", {
        event_category,
        // event_label,
        value: "1",
      });
    },
    station(value) {
      ls("VIPBidstation", value);
    },
    carType(value) {
      ls("VIPBidcarType", value);
    },
    userActive(value) {
      if (value) {
        this.load();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  padding: 0px !important;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}
::v-deep .range {
  background-color: #2196f32e !important;
}
::v-deep .ing {
  color: #ff0000d6 !important;
  font-weight: bolder;
}
</style>
