<template>
  <div>
    <transition>
      <div v-if="showAds">
        <v-container>
          <div
            class="d-flex"
            :class="{
              'justify-center': !$vuetify.breakpoint.smAndUp,
              'justify-end': $vuetify.breakpoint.smAndUp,
            }"
          >
            <div class="ads">
              <div class="image">
                <div class="close">
                  <v-btn class="mx-2" fab dark x-small @click="showAds = false">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
                <div class="title" @click="goURL">{{ ads.title }}</div>
                <v-carousel hide-delimiters height="250">
                  <v-carousel-item
                    eager
                    v-for="(item, i) in ads.images"
                    :key="i"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                    @click="goURL"
                  >
                    <v-img
                      eager
                      :src="item.src"
                      contain
                      height="100%"
                      max-height="240"
                      max-width="350"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </div>
          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
let showAds = false;
export default {
  name: "Ads",
  data: () => ({
    showAds,
    ads: {
      provider: "自營廣告 - Miky",
      title: "野馬 MUSTANG 2.3 ECO P版 - 148萬",
      url: "https://www.ruten.com.tw/item/show?22314385934082",
      images: [
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_504.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_959.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_484.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_416.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_184.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_495.jpg",
        },
        {
          src: "https://gcs.rimg.com.tw/g2/c/0b/02/22314385934082_335.jpg",
        },
      ],
    },
  }),
  computed: {},
  created() {
    this.showAds = this.$route.query.ads || this.showAds;
  },
  methods: {
    goURL() {
      gtag("event", "廣告", {
        event_category: this.ads.provider,
        event_label: "右下角版位",
        value: "1",
      });
      window.open(this.ads.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-window__prev,
::v-deep .v-window__next {
  top: calc(50% - 20px + 22px);
}
.ads-wrapper {
  position: absolute;
  width: 100%;
}
.ads {
  position: fixed;
  bottom: 33px;
  cursor: pointer;
}
.image {
  width: 350px;
  height: 248px;
  background-color: #111111e8;
}
.title {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #000000a8;
  z-index: 10;
  color: #fff;
  padding: 6px 10px;
  font-size: 16px !important;
}
.close {
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 20;
}
.v-leave-active {
  transition: opacity 0.4s;
}
.v-leave-to {
  opacity: 0;
}
</style>
