<template>
  <div>
    <header>
      <h3 class="float-left">車牌快找工具 Dashboard</h3>
    </header>
    <v-container fluid>
      <v-row>
        <v-col>
          <Plotly
            v-if="ready"
            :data="userPieData"
            :layout="userPieLayout"
            :display-mode-bar="false"
          ></Plotly>
        </v-col>
      </v-row>
      <Plotly
        v-if="ready"
        :data="userData"
        :layout="userLayout"
        :display-mode-bar="false"
      ></Plotly>
      <Plotly
        v-if="ready"
        :data="paymentData"
        :layout="paymentLayout"
        :display-mode-bar="false"
      ></Plotly>
      <Plotly
        v-if="ready"
        :data="searchData"
        :layout="searchLayout"
        :display-mode-bar="false"
      ></Plotly>
    </v-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import config from "@/libs/config.js";
import _ from "lodash";
import { Plotly } from "vue-plotly";

const formatter = new Intl.NumberFormat("zh-TW", {
  style: "decimal",
  currency: "TWD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default {
  name: "Trend",
  components: {
    Plotly,
  },
  data: () => ({
    ready: false,
    trend: null,
    payment: null,
    search: null,
    userLayout: {
      title: "轉換率",
      xaxis: {
        autotick: false,
        tickformat: "%m/%d",
      },
    },
  }),
  computed: {
    paymentLayout() {
      return {
        title:
          "付費人數/金額: $" +
          formatter.format(_.sumBy(this.payment, "amount") * 1000),
        xaxis: {
          autotick: false,
          tickformat: "%m/%d",
        },
      };
    },
    searchLayout() {
      return {
        title: "搜尋次數: " + formatter.format(_.sumBy(this.search, "count")),
        xaxis: {
          autotick: false,
          tickformat: "%m/%d",
        },
      };
    },
    userPieLayout() {
      return { title: `使用人數: ${this.users.length}` };
    },
    userPieData() {
      let activeUsers = this.users.filter((r) => r.active).length;
      let users = this.users.length - activeUsers;
      let data = [
        {
          values: [users, activeUsers],
          labels: [`純試用`, `付費`],
          type: "pie",
          showlegend: false,
        },
      ];
      return data;
    },
    userData() {
      let users = {
        x: this.trend.map((r) => r.date),
        y: this.trend.map((r) => r.users),
        name: "註冊人數",
      };
      let active = {
        x: this.trend.map((r) => r.date),
        y: this.trend.map((r) => r.active),
        name: "有效人數",
      };
      return [users, active];
    },
    paymentData() {
      let records = {
        x: this.payment.map((r) => r.date),
        y: this.payment.map((r) => r.records),
        name: "付費人數",
      };
      let amount = {
        x: this.payment.map((r) => r.date),
        y: this.payment.map((r) => r.amount),
        name: "付費金額(千)",
      };
      return [records, amount];
    },
    searchData() {
      let searchs = {
        x: this.search.map((r) => r._id),
        y: this.search.map((r) => r.count),
        name: "搜尋次數",
      };

      return [searchs];
    },
  },
  async created() {
    this.$vloading.show();
    await this.fetch();
    this.$vloading.hide();
    this.ready = true;
  },
  methods: {
    async fetch() {
      let res = await axios.get(`${config.API_URL}/dashboard/trend`);
      let data = res.data;
      this.users = data.users;
      this.search = data.searchLog;
      let groupUsers = _.groupBy(data.users, (row) => {
        return dayjs(row.createdAt).format("YYYY-MM-DD");
      });
      groupUsers = _.map(groupUsers, (users, date) => {
        return {
          date,
          users: users.length,
          active: users.filter((r) => r.active).length,
        };
      });
      this.trend = groupUsers;

      let groupPayments = _.groupBy(data.payment, (row) => {
        return dayjs(row.createdAt).format("YYYY-MM-DD");
      });
      groupPayments = _.map(groupPayments, (records, date) => {
        return {
          date,
          records: records.length,
          amount: _.sumBy(
            records,
            (record) => parseInt(record.TradeAmt) / 1000
          ),
        };
      });
      this.payment = groupPayments;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background-color: #1976d2;
  color: #fff;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
</style>
