<template>
  <div>
    <header>
      <h3 class="float-left">車牌快找工具</h3>
      <!-- <div class="float-right">
        <v-icon color="#fff" class="pointer" @click="dialog = true"
          >mdi-account</v-icon
        >
      </div> -->
    </header>
    <v-container>
      <v-row>
        <v-col cols="6" sm="2">
          <v-select
            v-model="head"
            :items="items"
            label="號碼首數"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2">
          <v-select
            v-model="tail"
            :items="items"
            label="號碼尾數"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2">
          <v-select
            v-model="station"
            :items="stations"
            label="監理單位"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2">
          <v-select
            v-model="carType"
            :items="carTypes"
            label="車種選項"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" sm="2"
          ><v-text-field
            v-model="keyword"
            label="號碼輸入"
            placeholder=""
            hide-details
            dense
            outlined
            clearable
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="2">
          <v-select
            v-model="feature"
            :items="features"
            label="功能查找"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-alert outlined dense type="info" color="primary">
            <li>
              增加專業付費版，專業版優化進牌更新速度(5分鐘內)，可快速搜尋，並加入流標車牌，提供
              10 天免費試用，可至
              <strong
                ><a class="error--text" href="/vip?openExternalBrowser=1"
                  >專業版</a
                ></strong
              >
              試用。
            </li>
            <li>
              可參考專業版的相關
              <strong>
                <a
                  class="error--text"
                  target="_blank"
                  href="https://youtube.com/@user-sr8dc3mi7u"
                >
                  教學影片
                </a>
              </strong>
            </li>
            <li>
              若更新時間超過 24 個小時未更新，有可能是電腦 cache，請先嘗試
              <a
                @click="clearls"
                class="font-weight-bold text-decoration-underline"
                >清除快取</a
              >
              、無痕視窗、換瀏覽器，若狀況還是一樣則請聯繫客服
              <strong>
                <a target="_blank" href="http://m.me/100089988214517">
                  車牌快找客服
                </a> </strong
              >。
            </li>
          </v-alert>
        </v-col>
      </v-row>
      <div class="mb-2">
        <VueScriptComponent
          script='<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8146417859027105"
     crossorigin="anonymous"></script>

<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8146417859027105"
     data-ad-slot="6191198126"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>'
        />
      </div>
      <v-row>
        <v-col class="text-center counts">
          {{ longList ? tags && tags.length : filterTags.length }} /
          {{ tags && tags.length }}
          <div class="updated">監理單位: {{ station }}</div>
          <div class="updated">車種選項: {{ carTypeText }}</div>
          <div class="updated">更新時間: {{ updatedAt }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center flex-wrap pb-10" id="result">
          <template v-for="(row, index) in filterTags">
            <div
              class="ma-2 tag"
              :class="{
                pick: pick.includes(row.tag),
                electric: carType == '_E' || carType == '_P',
                moto550: carType == '_M',
                motoY: carType == '_Y',
              }"
              large
              @click="toggle(row.tag)"
            >
              <div>{{ row.tag }}</div>
              <div class="station">{{ row.station }}</div>
              <div class="page">P.{{ row.page }}</div>
            </div>
            <div
              class="tag-ads my-5"
              v-if="$vuetify.breakpoint.mobile && index && index % 50 == 49"
            >
              <VueScriptComponent
                :script="
                  `<script async src=&quot;https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8146417859027105&quot;
     crossorigin=&quot;anonymous&quot;></script>

<ins class=&quot;adsbygoogle&quot;
     style=&quot;display:block&quot;
     data-ad-client=&quot;ca-pub-8146417859027105&quot;
     data-ad-slot=&quot;${randomAds}&quot;
     data-ad-format=&quot;auto&quot;
     data-full-width-responsive=&quot;true&quot;></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`
                "
              />
            </div>
          </template>
        </v-col>
        <!-- <v-col cols="12">
          <div class="mb-2">
            <VueScriptComponent
              script='<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8146417859027105"
     crossorigin="anonymous"></script>

<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8146417859027105"
     data-ad-slot="8576708379"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>'
            />
          </div>
        </v-col> -->
        <v-col
          v-if="longList && filterTags.length"
          cols="12"
          class="text-center pb-16"
        >
          <v-btn color="primary" @click="expendAll()">展開全部</v-btn>
        </v-col>
        <v-col cols="12" class="text-center" v-if="this.feature == '已標記'">
          <v-btn x-large color="primary" @click="downloadResult"
            >存成圖片</v-btn
          >
        </v-col>
      </v-row>
      <v-dialog persistent v-model="dialog" max-width="400px">
        <v-card>
          <v-card-title>會員資料</v-card-title>
          <v-card-text>
            <div v-if="!user">
              <v-btn color="#4267B2" dark @click="fbLogin" block
                >使用 Facebook 登入</v-btn
              >
            </div>
            <div v-else>
              <div>已登入</div>
              <div>
                使用期限為 {{ this.user.expiredAt }} (剩餘 {{ leftDate }} 天)
              </div>
            </div>
          </v-card-text>
          <template v-if="user && dialog">
            <v-card-title>購買方案</v-card-title>
            <v-card-text>
              <div class="box">
                <v-select
                  outlined
                  dense
                  label="選擇方案"
                  v-model="planPackage"
                  :items="[
                    { text: '購買 1 個月 ( NT$ 99 元 )', value: 99 },
                    { text: '購買 12 個月 ( NT$ 999 元 )', value: 999 },
                  ]"
                ></v-select>
                <div id="cardview-container" class="mb-4"></div>

                <v-btn
                  block
                  color="success"
                  :disabled="payDisabled"
                  @click="pay()"
                  >付款</v-btn
                >
              </div>
            </v-card-text>
          </template>
          <v-card-actions v-if="user && leftDate">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> 關閉 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <Ads></Ads>
    </v-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import _ from "lodash";
import ls from "local-storage";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import stations from "@/libs/stations.js";
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import config from "@/libs/config.js";
import Ads from "@/components/Ads.vue";
import VueScriptComponent from "vue-script-component";

const db = getFirestore(
  initializeApp({
    apiKey: "AIzaSyASpjLGI611MvP9s2qPxJgessHKRqXwx_I",
    projectId: "tags-35144",
    authDomain: "tags-35144.firebaseapp.com",
  })
);

export default {
  name: "Home",
  components: { Ads, VueScriptComponent },
  data: () => ({
    carTypes: [
      {
        text: "一般自小客車",
        value: "",
      },
      {
        text: "電動自小客車",
        value: "_E",
      },
      {
        text: "電動租賃車",
        value: "_T",
      },
      {
        text: "租賃小客貨車",
        value: "_R",
      },
      {
        text: "紅牌重機車",
        value: "_M",
      },
      {
        text: "黃牌重機車",
        value: "_Y",
      },
      {
        text: "白牌機車",
        value: "_H",
      },
      {
        text: "電動機車",
        value: "_P",
      },
      {
        text: "電動輕型機車",
        value: "_Q",
      },
    ],
    carType: "",
    userID: null,
    user: null,
    dialog: false,
    payDisabled: true,
    tags: [],
    updatedAt: "",
    keyword: "",
    tail: "",
    head: "",
    feature: "",
    station: "",
    planPackage: 0,
    pick: [],
    showAll: false,
    features: [
      { text: "無", value: "" },
      "已標記",
      { divider: true },
      "數字漸大",
      "數字漸小",
      { divider: true },
      "雙同數",
      "三同數",
      { divider: true },
      "疊數",
      "雙疊數",
      "三疊數",
      "四疊數",
      { divider: true },
      "總和 21",
      "總和 24",
      "總和 25",
      "總和 29",
      "總和 31",
      "總和 32",
      "總和 33",
      "總和 35",
    ],
    items: [
      { text: "不限", value: "" },
      "0",
      "1",
      "2",
      "3",
      "5",
      "6",
      "7",
      "8",
      "9",
    ],
  }),
  computed: {
    randomAds() {
      let ads = [
        "2427368461",
        "8576708379",
        "9241803228",
        "5525139240",
        "3026134266",
      ];
      return _.sample(ads);
    },
    carTypeText() {
      return _.find(this.carTypes, { value: this.carType }).text;
    },
    leftDate() {
      let left = 0;
      if (this.user) {
        left = dayjs(this.user.expiredAt).diff(dayjs(), "day");
      }
      return left < 0 ? 0 : left;
    },
    stations() {
      let mapStations = stations.map((station) => station.name);
      mapStations.unshift({ text: "全部", value: "全部" });
      return mapStations;
    },
    filterTags() {
      let tags = this.tags;
      if (this.station && this.station != "全部") {
        tags = tags.filter((row) => row.station == this.station);
      }
      if (this.keyword) {
        tags = tags.filter((row) => row.tag?.includes(this.keyword));
      }
      if (this.head) {
        tags = tags.filter((row) => row.tag?.split("-")[1][0] == this.head);
      }
      if (this.tail) {
        tags = tags.filter((row) => row.tag?.split("-")[1][3] == this.tail);
      }
      if (this.feature) {
        if (this.feature == "已標記") {
          tags = tags.filter((row) => this.pick?.includes(row.tag));
        }
        if (this.feature == "數字漸大") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] <= number[1] &&
              number[1] <= number[2] &&
              number[2] <= number[3]
            );
          });
        }
        if (this.feature == "數字漸小") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] >= number[1] &&
              number[1] >= number[2] &&
              number[2] >= number[3]
            );
          });
        }
        if (this.feature == "疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] == number[1] ||
              number[1] == number[2] ||
              number[2] == number[3]
            );
          });
        }
        if (this.feature == "雙疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return number[0] == number[1] && number[2] == number[3];
          });
        }
        if (this.feature == "三疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[1] == number[2]) ||
              (number[1] == number[2] && number[2] == number[3])
            );
          });
        }
        if (this.feature.includes("總和")) {
          let sum = this.feature.split(" ")[1];
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] * 1 + number[1] * 1 + number[2] * 1 + number[3] * 1 ==
              sum * 1
            );
          });
        }
        if (this.feature == "四疊數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              number[0] == number[1] &&
              number[1] == number[2] &&
              number[2] == number[3]
            );
          });
        }
        if (this.feature == "雙同數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[2] == number[3]) ||
              (number[0] == number[2] && number[1] == number[3])
            );
          });
        }
        if (this.feature == "三同數") {
          tags = tags.filter((row) => {
            let number = row.tag?.split("-")[1];
            return (
              (number[0] == number[1] && number[1] == number[2]) ||
              (number[1] == number[2] && number[2] == number[3]) ||
              (number[0] == number[1] && number[1] == number[3]) ||
              (number[0] == number[2] && number[2] == number[3])
            );
          });
        }
      }
      if (this.longList) {
        return _.take(tags, 300);
      } else {
        return tags;
      }
    },
    longList() {
      return (
        this.station == "全部" &&
        !this.showAll &&
        !this.feature &&
        !this.head &&
        !this.tail &&
        !this.keyword
      );
    },
  },
  async created() {
    // await axios.post(config.API_URL + "/test");
    this.pick = _.cloneDeep(ls("pick")) || [];
    this.station = ls("station") || "全部";
    this.carType = ls("carType") || "";
    this.$vloading.show();
    this.getData();
    gtag("event", "主目錄", {
      event_category: "免費版",
      // event_label,
      value: "1",
    });
  },
  // async mounted() {
  //   this.loadFBLibrary();

  //   TPDirect.setupSDK(config.APP_ID, config.APP_KEY, config.APP_ENV);

  //   TPDirect.card.onUpdate((update) => {
  //     this.payDisabled = !update.canGetPrime;
  //   });
  // },
  methods: {
    async clearls() {
      if (confirm("確認清除網站快取？")) {
        ls.clear();
        location.reload(true);
      }
    },
    async loadFBLibrary() {
      window.fbAsyncInit = () => {
        FB.init({
          appId: "1049530835992609",
          cookie: true,
          xfbml: true,
          version: "v12.0",
        });

        FB.getLoginStatus((res) => {
          this.setLogin(res);
        });

        FB.AppEvents.logPageView();
      };
      (function(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    },
    async fbLogin() {
      FB.login(this.setLogin);
    },
    async setLogin(res) {
      if (res.authResponse && res.authResponse.userID) {
        this.userID = res.authResponse.userID;
        try {
          await this.getUserInfo();

          if (!this.user) {
            this.user = {
              expiredAt: dayjs()
                .add(3, "day")
                .format("YYYY-MM-DD"),
            };
            await setDoc(doc(db, "users", this.userID), this.user);
          }
          await this.getData();
        } catch (error) {
          console.log(error);
        }
      }
      if (!this.user || this.leftDate <= 0) {
        this.dialog = true;
      }
    },
    async getUserInfo() {
      this.user = (await getDoc(doc(db, "users", this.userID))).data();
    },
    setupCardField() {
      this.$nextTick(() => {
        TPDirect.card.setup(
          "#cardview-container",
          {
            color: "#000",
            fontSize: "17px",
            lineHeight: "24px",
            fontWeight: "400",
            errorColor: "error",
            placeholderColor: "",
          },
          { isUsedCcv: false }
        );
      });
    },
    async pay() {
      this.$vloading.show();

      TPDirect.card.getPrime(async (info) => {
        if (info && info.card && info.card.prime) {
          let prime = info.card.prime;
          let price = this.planPackage;
          let userID = this.userID;
          let result = await axios.post(config.API_URL + "/tappay", {
            prime,
            price,
            userID,
          });

          if (result.data.status) {
            this.$toast.error("刷卡失敗，請重新嘗試！");
          } else {
            await this.getUserInfo();
            await this.getData();
          }
          this.payDisabled = true;
          this.planPackage = 0;
          this.$vloading.hide();
          this.$toast.success("方案購買完成！");
        }
      });
    },
    async expendAll() {
      this.$vloading.show();
      setTimeout(() => {
        this.$nextTick(() => {
          this.showAll = true;
          this.$vloading.hide();
          // this.$nextTick(() => {

          // });
        });
      }, 1000);
    },
    async getData() {
      // if (dayjs(this.user.expiredAt).diff(dayjs(), "day") <= 0) {
      //   return false;
      // }
      this.dialog = false;
      this.$vloading.show();

      let lsData = ls(`tags${this.carType}`);
      if (
        lsData &&
        lsData.create &&
        dayjs(lsData.create).add(30, "minute") > dayjs()
      ) {
        this.tags = lsData.tags;
        this.updatedAt = lsData.updatedAt;
        this.$vloading.hide();
        return false;
      }

      let result = [];
      result = (
        await axios.get(
          `https://test.tnstiger.workers.dev/all${this.carType}.json`
        )
      ).data.allTags;

      let updatedAt = (await axios.get(config.API_URL + "/updatedAt")).data
        .updatedAt;
      ls(`tags${this.carType}`, {
        tags: result,
        updatedAt,
        create: new Date(),
      });
      this.updatedAt = updatedAt;
      this.tags = result;
      this.$vloading.hide();
    },
    toggle(tag) {
      if (this.pick.includes(tag)) {
        this.pick = _.without(this.pick, tag);
      } else {
        this.pick.push(tag);
      }
      ls("pick", this.pick);
    },
    downloadResult() {
      htmlToImage.toPng(document.getElementById("result")).then((dataUrl) => {
        download(dataUrl, "選擇車牌.png");
      });
    },
  },
  watch: {
    station(station) {
      ls("station", station);
      this.showAll = false;
    },
    carType(value) {
      ls("carType", value);
      this.getData();
    },
    dialog(value) {
      if (this.user && this.dialog) {
        this.setupCardField();
      }
    },
    user(value) {
      if (this.user && this.dialog) {
        this.setupCardField();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background-color: #1976d2;
  color: #fff;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
.tag-ads {
  // position: relative;
  // left: -50%;
  width: 300px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.tag {
  background: #fff;
  border: 2px solid rgb(135, 135, 135);
  border-radius: 10px;
  padding: 10px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  width: 140px;

  &.electric {
    div:first-child {
      color: #368536;
    }
  }
  &.moto550 {
    div:first-child {
      color: #c83339;
    }
  }
  &.motoY {
    div:first-child {
      color: #e3a51c;
    }
  }
  .page {
    font-size: 12px;
    font-weight: 400;
  }
  .station {
    font-size: 14px;
    font-weight: 400;
  }

  &.pick {
    border-color: rgb(80, 140, 82);
    background-color: rgb(227, 240, 228);
  }
}
.counts {
  font-size: 20px;
  font-weight: 600;
}
.updated {
  font-size: 14px;
  font-weight: 500;
}
.box {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px 10px;
}

@media (hover: hover) {
  .tag:hover {
    background-color: rgb(227, 240, 228);
  }
}
</style>
