var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0"},[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_vm._v(" - 更新時間: "+_vm._s(_vm.updatedAt)+" ")]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_vm._v(" - 有效: "+_vm._s(_vm.sum.valid)+" ")]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_vm._v(" - 暫停: "+_vm._s(_vm.sum.issue)+" ")]),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_vm._v(" - 系統狀態: "),_c('v-icon',{attrs:{"color":_vm.lastFetch ? 'green' : 'red'}},[_vm._v(" mdi-circle ")])],1)],1),_c('v-row',{staticClass:"mt-0 mb-0"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"items":_vm.stations,"item-value":"name","item-text":"name","label":"單位","outlined":"","dense":"","hide-details":""},model:{value:(_vm.selected.station),callback:function ($$v) {_vm.$set(_vm.selected, "station", $$v)},expression:"selected.station"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{attrs:{"items":_vm.carTypes,"item-value":"selPlateType","item-text":function (item) {
              return ((_vm.mapType(item.selPlateType)) + " (" + (item.selPlateType) + ")");
            },"label":"車牌別","outlined":"","dense":"","hide-details":""},model:{value:(_vm.selected.type),callback:function ($$v) {_vm.$set(_vm.selected, "type", $$v)},expression:"selected.type"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.filter = ''}}},[_vm._v("顯示全部")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.filter = 'valid'}}},[_vm._v("顯示有效")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.filter = 'issue'}}},[_vm._v("顯示暫停")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.restartVIPRenew}},[_vm._v("重啟更新")])],1)],1),(_vm.table)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filterList},scopedSlots:_vm._u([{key:"item.carType.selPlateType",fn:function(ref){
            var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.selected.type = item.carType.selPlateType}}},[_vm._v(_vm._s(_vm.mapType(item.carType.selPlateType))+" ("+_vm._s(item.carType.selPlateType)+") ")])]}},{key:"item.queryType",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.queryType == "0" ? "一般" : "流標")+" ")]}},{key:"item.station.name",fn:function(ref){
            var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){_vm.selected.station = item.station.name}}},[_vm._v(_vm._s(item.station.name))])]}},{key:"item.valid",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.valid ? 'green' : 'red'}},[_vm._v(" mdi-circle ")])]}},{key:"item.issue",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.toggle(item, 'issue')}},model:{value:(item.issue),callback:function ($$v) {_vm.$set(item, "issue", $$v)},expression:"item.issue"}})]}},{key:"item.processing",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.toggle(item, 'processing')}},model:{value:(item.processing),callback:function ($$v) {_vm.$set(item, "processing", $$v)},expression:"item.processing"}}),(item.processing)?_c('div',[_vm._v(_vm._s(item.processingAt))]):_vm._e()]}},{key:"item.force",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{on:{"click":function($event){return _vm.toggle(item, 'force')}},model:{value:(item.force),callback:function ($$v) {_vm.$set(item, "force", $$v)},expression:"item.force"}})]}},{key:"item.fetchTags",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.fetchTags || item.tags || 0)+" ("+_vm._s(_vm.getTags(item))+") ")]}}],null,false,2010527466)}):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('h3',{staticClass:"float-left"},[_vm._v("車牌快找工具 Dashboard")])])}]

export { render, staticRenderFns }