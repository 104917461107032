module.exports = [
  {
    selDeptCode: "2",
    selStationCode: "20",
    name: "臺北市區監理所",
  },
  {
    selDeptCode: "2",
    selStationCode: "21",
    name: "士林監理站",
  },
  {
    selDeptCode: "2",
    selStationCode: "25",
    name: "基隆監理站",
  },
  {
    selDeptCode: "2",
    selStationCode: "26",
    name: "金門監理站",
  },
  {
    selDeptCode: "2",
    selStationCode: "28",
    name: "連江監理站",
  },
  {
    selDeptCode: "3",
    selStationCode: "30",
    name: "高雄市區監理所",
  },
  {
    selDeptCode: "3",
    selStationCode: "31",
    name: "苓雅監理站",
  },
  {
    selDeptCode: "3",
    selStationCode: "33",
    name: "旗山監理站",
  },
  {
    selDeptCode: "4",
    selStationCode: "40",
    name: "臺北區監理所",
  },
  {
    selDeptCode: "4",
    selStationCode: "41",
    name: "板橋監理站",
  },
  {
    selDeptCode: "4",
    selStationCode: "43",
    name: "宜蘭監理站",
  },
  {
    selDeptCode: "4",
    selStationCode: "44",
    name: "花蓮監理站",
  },
  {
    selDeptCode: "4",
    selStationCode: "45",
    name: "玉里監理分站",
  },
  {
    selDeptCode: "4",
    selStationCode: "46",
    name: "蘆洲監理站",
  },
  {
    selDeptCode: "5",
    selStationCode: "50",
    name: "新竹區監理所",
  },
  {
    selDeptCode: "5",
    selStationCode: "51",
    name: "新竹市監理站",
  },
  {
    selDeptCode: "5",
    selStationCode: "52",
    name: "桃園監理站",
  },
  {
    selDeptCode: "5",
    selStationCode: "53",
    name: "中壢監理站",
  },
  {
    selDeptCode: "5",
    selStationCode: "54",
    name: "苗栗監理站",
  },
  {
    selDeptCode: "6",
    selStationCode: "60",
    name: "臺中區監理所",
  },
  {
    selDeptCode: "6",
    selStationCode: "61",
    name: "臺中市監理站",
  },
  {
    selDeptCode: "6",
    selStationCode: "62",
    name: "埔里監理分站",
  },
  {
    selDeptCode: "6",
    selStationCode: "63",
    name: "豐原監理站",
  },
  {
    selDeptCode: "6",
    selStationCode: "64",
    name: "彰化監理站",
  },
  {
    selDeptCode: "6",
    selStationCode: "65",
    name: "南投監理站",
  },
  {
    selDeptCode: "7",
    selStationCode: "70",
    name: "嘉義區監理所",
  },
  {
    selDeptCode: "7",
    selStationCode: "71",
    name: "東勢監理分站",
  },
  {
    selDeptCode: "7",
    selStationCode: "72",
    name: "雲林監理站",
  },
  {
    selDeptCode: "7",
    selStationCode: "73",
    name: "新營監理站",
  },
  {
    selDeptCode: "7",
    selStationCode: "74",
    name: "臺南監理站",
  },
  {
    selDeptCode: "7",
    selStationCode: "75",
    name: "麻豆監理站",
  },
  {
    selDeptCode: "7",
    selStationCode: "76",
    name: "嘉義市監理站",
  },
  {
    selDeptCode: "8",
    selStationCode: "80",
    name: "高雄區監理所",
  },
  {
    selDeptCode: "8",
    selStationCode: "81",
    name: "臺東監理站",
  },
  {
    selDeptCode: "8",
    selStationCode: "82",
    name: "屏東監理站",
  },
  {
    selDeptCode: "8",
    selStationCode: "83",
    name: "恆春監理分站",
  },
  {
    selDeptCode: "8",
    selStationCode: "84",
    name: "澎湖監理站",
  },
];
