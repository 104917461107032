import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { LoadingPlugin } from "vuetify-loading-overlay";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 3000,
});
Vue.use(LoadingPlugin, {
  spinnerProps: {
    color: "#0F4C81",
    width: "7",
    size: 70,
  },
  overlayProps: {
    "z-index": 9999,
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
