import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Test from "../views/Test.vue";
import Privacy from "../views/Privacy.vue";
import VIP from "../views/VIP.vue";
import Configs from "../views/Configs.vue";
import Station from "../views/dashboard/Station.vue";
import Trend from "../views/dashboard/Trend.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/dashboard/station",
    name: "Station",
    component: Station,
  },
  {
    path: "/dashboard/trend",
    name: "Trend",
    component: Trend,
  },
  {
    path: "/vip",
    name: "VIP",
    component: VIP,
  },
  {
    path: "/configs",
    name: "Configs",
    component: Configs,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
