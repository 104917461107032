module.exports = [
  {
    score: 100,
    label: (number) => "四疊數 +100",
    method: (number) => {
      return (
        number[0] == number[1] &&
        number[1] == number[2] &&
        number[2] == number[3]
      );
    },
  },
  {
    score: 70,
    label: (number) =>
      /(9527|0857|5678|6789)/.exec(number)?.[1] + "特殊號碼 +70",
    method: (number) => {
      return /(9527|0857|5678|6789)/.test(number);
    },
  },
  {
    score: 30,
    label: (number) => "雙同數 +30",
    method: (number) => {
      return (
        (number[0] == number[1] && number[2] == number[3]) ||
        (number[0] == number[2] && number[1] == number[3]) ||
        (number[0] == number[3] && number[1] == number[2])
      );
    },
  },
  {
    score: 20,
    label: (number) => "順號 +20",
    method: (number) => {
      return (
        (number[1] - number[0] <= 1 &&
          number[2] - number[1] <= 1 &&
          number[3] - number[2] <= 1 &&
          number[1] - number[0] >= 0 &&
          number[2] - number[1] >= 0 &&
          number[3] - number[2] >= 0) ||
        (number[0] - number[1] <= 1 &&
          number[1] - number[2] <= 1 &&
          number[2] - number[3] <= 1 &&
          number[0] - number[1] >= 0 &&
          number[1] - number[2] >= 0 &&
          number[2] - number[3] >= 0)
      );
    },
  },
  {
    score: 20,
    label: (number) => "三同數 +20",
    method: (number) => {
      return (
        (number[0] == number[1] && number[1] == number[2]) ||
        (number[1] == number[2] && number[2] == number[3]) ||
        (number[0] == number[1] && number[1] == number[3]) ||
        (number[0] == number[2] && number[2] == number[3])
      );
    },
  },
  {
    score: 20,
    label: (number) =>
      /\d{2}(66|68|88|89|99)/.exec(number)?.[1] + "尾數吉號 +20",
    method: (number) => {
      return /\d{2}(66|68|88|89|99)/.test(number);
    },
  },
  {
    score: 10,
    label: (number) => "數字漸大 +10",
    method: (number) => {
      return (
        number[0] <= number[1] &&
        number[1] <= number[2] &&
        number[2] <= number[3]
      );
    },
  },
  {
    score: -30,
    label: (number) => "4 數字 -30",
    method: (number) => {
      return /4/.test(number);
    },
  },
  {
    score: -30,
    label: (number) => "2266 數字 -30",
    method: (number) => {
      return /2266/.test(number);
    },
  },
];
