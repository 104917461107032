<template>
  <div>
    <header>
      <h3 class="float-left">車牌快找工具 VIP</h3>
      <div class="float-left">
        <div
          class="tab pointer ml-4"
          :class="{ active: tab == 'search' }"
          @click="tab = 'search'"
        >
          選號查詢
        </div>
      </div>
      <div class="float-left ml-2">
        <div
          class="tab pointer"
          :class="{ active: tab == 'bid' }"
          @click="tab = 'bid'"
        >
          標牌查詢
        </div>
      </div>
      <div class="float-right">
        <v-icon color="#fff" class="pointer" @click="dialog = true">
          mdi-account
        </v-icon>
      </div>
    </header>
    <v-dialog
      persistent
      v-model="dialog"
      max-width="400px"
      style="z-index:101;"
    >
      <v-card>
        <v-card-title>會員資料</v-card-title>
        <v-card-text>
          <div v-if="!user">
            <v-btn large color="#DC4F41" dark @click="login" block>
              使用 Google 登入
            </v-btn>
          </div>
          <div v-else>
            <div>啟用狀態: {{ userActive ? "已" : "未" }}啟用</div>
            <div v-if="this.user">
              <div>使用者: {{ this.user.uid }}</div>
              <div>
                使用期限: {{ this.user.expiredAt }} (剩餘 {{ leftDate }} 天)
              </div>
              <div>
                <v-btn class="mt-2" small color="error" @click="logout">
                  登出
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
        <template v-if="user && userActive">
          <v-divider class="my-1 mx-6"></v-divider>
          <v-card-title>車牌通知</v-card-title>
          <v-card-text>
            <div class="mb-2">
              可以輸入通知車牌號碼，先點擊按鈕「Line Notify
              綁定」，當通知的車牌號碼出現在系統時，會主動推播至 Line Notify
              所選擇的頻道。
            </div>
            <div>
              <v-btn v-if="user.token" small color="success" @click="bindLine">
                已綁定 (重新綁定 Line Notify)
              </v-btn>
              <v-btn v-else small color="error" @click="bindLine">
                Line Notify 綁定
              </v-btn>
            </div>

            <v-row v-if="user.token" align="center" class="mt-2">
              <v-col cols="12">
                <v-checkbox
                  v-model="user.notify"
                  class="mt-0 pt-0"
                  hide-details
                  label="啟用通知"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.notifyType"
                  :items="carTypes"
                  item-value="selPlateType"
                  :item-text="
                    (item) => {
                      return `${mapType(item.selPlateType)} (${
                        item.selPlateType
                      })`;
                    }
                  "
                  label="車牌別"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  label="通知號碼"
                  v-model="user.notifyTag"
                  hint="請輸入完整車牌，例：ABC-1234"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-if="user">
          <v-divider class="my-1 mx-6 mt-0"></v-divider>
          <v-card-title>購買方案</v-card-title>
          <v-card-text>
            <v-select
              class="mb-4"
              outlined
              dense
              label="選擇方案"
              v-model="price"
              hint="建議用信用卡付款，ATM 和超商繳費有進帳延遲，無法立即開通！"
              persistent-hint
              :items="packages"
            ></v-select>
            <v-btn block color="success" @click="pay()">付款</v-btn>
          </v-card-text>
        </template>
        <v-card-actions v-if="userActive">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> 關閉 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Search v-if="tab == 'search'" :user="user" :auth="auth"></Search>
    <Bid
      v-if="tab == 'bid'"
      :user="user"
      :auth="auth"
      :userActive="userActive"
    ></Bid>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import config from "@/libs/config.js";
import _ from "lodash";
import crypto from "crypto";
import submit from "submit-form";
import ls from "local-storage";
import carTypes from "@/libs/carTypes.js";
import Search from "@/components/Search.vue";
import Bid from "@/components/Bid.vue";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { initializeApp, getApps, getApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyASpjLGI611MvP9s2qPxJgessHKRqXwx_I",
  authDomain: "tags-35144.firebaseapp.com",
  projectId: "tags-35144",
  // appId: "1:162968351088:web:f9e6232f5d1265773f1fb5",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const provider = new GoogleAuthProvider();
const auth = getAuth();

const merchantID = "3364567";
const hashKey = "HPohhJr081VfDApK";
const hashIV = "V1BqOK0bMyqvoc1G";

const clientID = "W5JOpJMBRyApcbZabQ4RKX";
const callbackURL = "https://car-tags.com/vip";
const typeMapping = {
  1: "自用小客貨車",
  h: "電動租賃車",
  g: "電動自小客",
  // i: "電動小營客車",
  G: "紅牌重機車",
  F: "黃牌重機車",
  P: "電動普通重型機車",
  H: "普通重型機車",
  2: "租賃小客貨車",
  3: "營業小客車",
  6: "自用大貨車",
  Q: "電動普通輕型機車",
};

export default {
  name: "VIP",
  components: { Search, Bid },
  data: () => ({
    tab: "search",
    auth,
    carTypes,
    initReady: false,
    code: null,
    userID: null,
    user: null,
    dialog: false,
    payDisabled: true,

    price: 0,
    packages: [
      { text: "請選擇", value: 0 },
      { text: "使用 1 個月 ( NT$ 99 元 )", value: 99 },
      { text: "使用 3 個月 ( NT$ 250 元 )", value: 250 },
      { text: "使用 12 個月 ( NT$ 999 元 )", value: 999 },
    ],
  }),
  computed: {
    leftDate() {
      if (!this.user) return 0;
      if (!this.user.expiredAt) return 0;
      let day = dayjs(this.user.expiredAt).diff(dayjs(), "day");
      return day <= 0 ? 0 : day;
    },
    userActive() {
      if (!this.user) return false;
      if (!this.user.expiredAt) return false;
      if (dayjs() >= dayjs(this.user.expiredAt)) return false;
      return true;
    },
  },
  async created() {
    this.tab = ls("VIPtab") || "search";
    this.code = this.$route.query.code;
    let query = Object.assign({}, this.$route.query);
    if (query.code) {
      delete query.code;
      delete query.state;
      this.$router.replace({ query });
    }

    await this.init();
  },
  methods: {
    mapType(type) {
      return typeMapping[type];
    },
    async setNotify() {
      await axios.post(
        `${config.API_URL}/setNotify`,
        _.pick(this.user, ["uid", "notify", "notifyType", "notifyTag"])
      );
      if (this.user.notify) {
        this.$toast.success(`已啟用通知`);
      } else {
        this.$toast.error(`已取消通知`);
      }
    },
    async setNotifyToken() {
      await axios.post(`${config.API_URL}/setNotifyToken`, {
        uid: this.user.uid,
        code: this.code,
      });
      this.code = null;

      this.$toast.success("已成功連動 Line Notify");
      this.initUser(this.user);
    },
    async logout() {
      await signOut(auth);
      // window.location.reload(true);
    },
    async init() {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.initUser(user);
        } else {
          this.dialog = true;
        }
      });
    },
    async initUser(user) {
      if (user.user) {
        return false;
      }

      let userInfo = (await axios.get(`${config.API_URL}/user/${user.uid}`))
        .data;

      user.expiredAt = userInfo?.expiredAt || null;
      user.active = userInfo?.active || false;
      user.token = userInfo?.token || false;
      user.notify = userInfo?.notify || false;
      user.notifyTag = userInfo?.notifyTag || "";
      user.notifyType = userInfo?.notifyType || "";
      this.user = { ...user };

      this.dialog = this.userActive ? false : true;
      if (this.code) {
        this.setNotifyToken();
      }

      this.$nextTick(() => {
        this.initReady = true;
      });
    },
    bindLine() {
      location.href = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${clientID}&redirect_uri=${callbackURL}&scope=notify&state=tags`;
    },

    async login() {
      signInWithPopup(auth, provider)
        .then((user) => {
          this.initUser(user);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async pay() {
      let payload = {
        TradeDesc: "新車牌快找 VIP",
        PaymentType: "aio",
        MerchantTradeDate: dayjs().format("YYYY/MM/DD HH:mm:ss"),
        MerchantTradeNo:
          dayjs().format("YYYYMMDDHHmmss") + _.random(1000, 9999),
        MerchantID: merchantID,
        ReturnURL: "https://car-tags-api.goodideas-studio.com/ecpayCallback",
        ItemName: _.find(this.packages, { value: this.price }).text,
        TotalAmount: this.price,
        ChoosePayment: "ALL",
        EncryptType: 1,
        ClientBackURL: "https://car-tags.com/vip",
        CustomField1: this.user.uid,
        CustomField2: this.user.email,
        CustomField3: this.user.displayName,
      };
      submit("https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5", {
        method: "POST",
        body: {
          ...payload,
          CheckMacValue: this.generateCheckMacValue(payload, hashKey, hashIV),
        },
      });
    },
    generateCheckMacValue(data, hashKey, hashIV) {
      const keys = Object.keys(data).sort((l, r) => (l > r ? 1 : -1));
      let checkValue = "";
      for (const key of keys) {
        checkValue += `${key}=${data[key]}&`;
      }
      checkValue = `HashKey=${hashKey}&${checkValue}HashIV=${hashIV}`; // There is already an & in the end of checkValue
      checkValue = encodeURIComponent(checkValue).toLowerCase();
      checkValue = checkValue
        .replace(/%20/g, "+")
        .replace(/%2d/g, "-")
        .replace(/%5f/g, "_")
        .replace(/%2e/g, ".")
        .replace(/%21/g, "!")
        .replace(/%2a/g, "*")
        .replace(/%28/g, "(")
        .replace(/%29/g, ")")
        .replace(/%20/g, "+");

      checkValue = crypto
        .createHash("sha256")
        .update(checkValue)
        .digest("hex");
      checkValue = checkValue.toUpperCase();
      return checkValue;
    },
  },
  watch: {
    "user.notify"() {
      if (this.initReady) {
        this.setNotify();
      }
    },
    "user.notifyType"() {
      if (this.initReady) {
        this.user.notify = false;
      }
    },
    "user.notifyTag"() {
      if (this.initReady) {
        this.user.notify = false;
      }
    },
    tab(value) {
      ls("VIPtab", value);
      let event_category = "";
      switch (value) {
        case "search":
          event_category = "選號查詢";
          break;
        case "bid":
          event_category = "標牌查詢";
          break;
      }
      gtag("event", "主目錄", {
        event_category,
        // event_label,
        value: "1",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background-color: #1976d2;
  color: #fff;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}

.tab {
  font-size: 14px;
}
.tab.active {
  border-bottom: 1px solid #fff;
  height: 38px;
}
</style>
