module.exports = [
  {
    selEnergyType: "",
    name: "請選擇",
  },
  {
    selEnergyType: "C",
    name: "非電能",
  },
  {
    selEnergyType: "E",
    name: "純電能",
  },
];
