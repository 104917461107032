module.exports = [
  "請選擇",
  "004,臺灣銀行",
  "005,土地銀行",
  "006,合庫商銀",
  "007,第一銀行",
  "008,華南銀行",
  "009,彰化銀行",
  "011,上海銀行",
  "012,台北富邦",
  "013,國泰世華",
  "016,高雄銀行",
  "017,兆豐銀行",
  "018,農業金庫",
  "020,瑞穗銀行",
  "021,花旗(台灣)銀行",
  "022,美國銀行",
  "023,盤谷銀行",
  "025,首都銀行",
  "039,澳商澳盛銀行",
  "048,王道銀行",
  "050,臺灣企銀",
  "052,渣打商銀",
  "053,台中銀行",
  "054,京城商銀",
  "072,德意志銀行",
  "075,東亞銀行",
  "081,匯豐(台灣)銀行",
  "082,巴黎銀行",
  "101,瑞興銀行",
  "102,華泰銀行",
  "103,臺灣新光商銀",
  "104,台北五信",
  "108,陽信銀行",
  "114,基隆一信",
  "115,基隆二信",
  "118,板信銀行",
  "119,淡水一信",
  "120,淡水信合社",
  "124,宜蘭信合社",
  "127,桃園信合社",
  "130,新竹一信",
  "132,新竹三信",
  "146,台中二信",
  "147,三信銀行",
  "158,彰化一信",
  "161,彰化五信",
  "162,彰化六信",
  "163,彰化十信",
  "165,鹿港信合社",
  "178,嘉義三信",
  "188,台南三信",
  "204,高雄三信",
  "215,花蓮一信",
  "216,花蓮二信",
  "222,澎湖一信",
  "223,澎湖二信",
  "224,金門信合社",
  "512,雲林漁會",
  "515,嘉義漁會",
  "517,南市漁會",
  "518,南縣漁會",
  "520,小港漁會",
  "520,高雄漁會",
  "521,永安漁會",
  "521,林園漁會",
  "521,梓官漁會",
  "521,興達港漁會",
  "521,彌陀漁會",
  "523,東港漁會",
  "523,枋寮漁會",
  "523,林邊漁會",
  "523,琉球漁會",
  "524,新港漁會",
  "525,澎湖漁會",
  "526,金門漁會",
  "600,七股農會",
  "600,二水農會",
  "600,八里農會",
  "600,八德農會",
  "600,三芝農會",
  "600,三星農會",
  "600,三重農會",
  "600,三峽農會",
  "600,三義農會",
  "600,三灣農會",
  "600,下營農會",
  "600,土城農會",
  "600,土庫農會",
  "600,士林農會",
  "600,大內農會",
  "600,大甲農會",
  "600,大安農會",
  "600,大村農會",
  "600,大肚農會",
  "600,大里農會",
  "600,大城農會",
  "600,大湖農會",
  "600,大雅農會",
  "600,大園農會",
  "600,大溪農會",
  "600,山上農會",
  "600,中和農會",
  "600,中埔農會",
  "600,中華民國農會",
  "600,中寮農會",
  "600,五股農會",
  "600,五結農會",
  "600,仁愛農會",
  "600,仁德農會",
  "600,元長農會",
  "600,內埔農會",
  "600,內湖農會",
  "600,公館農會",
  "600,太平農會",
  "600,木柵農會",
  "600,水里農會",
  "600,水林農會",
  "600,冬山農會",
  "600,北斗農會",
  "600,北投農會",
  "600,北埔農會",
  "600,北港農會",
  "600,外埔農會",
  "600,左鎮農會",
  "600,平溪農會",
  "600,平鎮農會",
  "600,玉溪農會",
  "600,田中農會",
  "600,田尾農會",
  "600,白河農會",
  "600,石岡農會",
  "600,石門農會",
  "600,石碇農會",
  "600,光豐農會",
  "600,后里農會",
  "600,安定農會",
  "600,汐止農會",
  "600,竹山農會",
  "600,竹北農會",
  "600,竹東農會",
  "600,竹南農會",
  "600,西湖農會",
  "600,伸港農會",
  "600,壯圍農會",
  "600,沙鹿農會",
  "600,芎林農會",
  "600,卓蘭農會",
  "600,和平農會",
  "600,和美農會",
  "600,坪林農會",
  "600,官田農會",
  "600,宜蘭農會",
  "600,東山農會",
  "600,東勢區農會",
  "600,東勢鄉農會",
  "600,板橋農會",
  "600,林口農會",
  "600,林內農會",
  "600,社頭農會",
  "600,花蓮農會",
  "600,花壇農會",
  "600,金山農會",
  "600,阿里山農會",
  "600,信義農會",
  "600,南化農會",
  "600,南庄農會",
  "600,南投農會",
  "600,南港農會",
  "600,南龍漁會",
  "600,後壁農會",
  "600,後龍農會",
  "600,苑裡農會",
  "600,苗栗農會",
  "600,員山農會",
  "600,埔里農會",
  "600,埔鹽農會",
  "600,峨眉農會",
  "600,桃園農會",
  "600,桃園漁會",
  "600,泰山農會",
  "600,烏日農會",
  "600,草屯農會",
  "600,高雄農會",
  "600,國姓農會",
  "600,基隆農會",
  "600,基隆漁會",
  "600,梧棲農會",
  "600,淡水農會",
  "600,深坑農會",
  "600,清水農會",
  "600,通苑漁會",
  "600,通霄農會",
  "600,造橋農會",
  "600,魚池農會",
  "600,鹿谷農會",
  "600,鹿港農會",
  "600,麥寮農會",
  "600,麻豆農會",
  "600,復興農會",
  "600,景美農會",
  "600,湖口農會",
  "600,新化農會",
  "600,新市農會",
  "600,新竹農會",
  "600,新竹漁會",
  "600,新店農會",
  "600,新社農會",
  "600,新屋農會",
  "600,新埔農會",
  "600,新莊農會",
  "600,新豐農會",
  "600,楊梅農會",
  "600,溪州農會",
  "600,溪湖農會",
  "600,獅潭農會",
  "600,瑞芳農會",
  "600,瑞芳漁會",
  "600,瑞穗農會",
  "600,萬里漁會",
  "600,彰化農會",
  "600,彰化漁會",
  "600,福興農會",
  "600,臺中農會",
  "600,臺南農會",
  "600,銅鑼農會",
  "600,鳳榮農會",
  "600,潭子農會",
  "600,線西農會",
  "600,學甲農會",
  "600,樹林農會",
  "600,橫山農會",
  "600,頭份農會",
  "600,頭城農會",
  "600,頭城漁會",
  "600,頭屋農會",
  "600,龍井農會",
  "600,龍崎農會",
  "600,龍潭農會",
  "600,龜山農會",
  "600,礁溪農會",
  "600,羅東農會",
  "600,關西農會",
  "600,關廟農會",
  "600,霧峰農會",
  "600,蘇澳漁會",
  "612,神岡農會",
  "612,豐原農會",
  "613,名間農會",
  "613,集集農會",
  "614,二林農會",
  "614,永靖農會",
  "614,竹塘農會",
  "614,秀水農會",
  "614,芬園農會",
  "614,芳苑農會",
  "614,員林農會",
  "614,埔心農會",
  "614,埤頭農會",
  "616,二崙農會",
  "616,口湖農會",
  "616,大埤農會",
  "616,斗六農會",
  "616,斗南農會",
  "616,古坑農會",
  "616,台西農會",
  "616,四湖農會",
  "616,西螺農會",
  "616,虎尾農會",
  "616,崙背農會",
  "616,莿桐農會",
  "616,褒忠農會",
  "617,大林農會",
  "617,六腳農會",
  "617,太保農會",
  "617,水上農會",
  "617,布袋農會",
  "617,民雄農會",
  "617,朴子農會",
  "617,竹崎農會",
  "617,東石農會",
  "617,梅山農會",
  "617,鹿草農會",
  "617,番路農會",
  "617,新港農會",
  "617,溪口農會",
  "617,義竹農會",
  "617,嘉義農會",
  "618,六甲農會",
  "618,北門農會",
  "618,永康農會",
  "618,玉井農會",
  "618,西港農會",
  "618,佳里農會",
  "618,柳營農會",
  "618,將軍農會",
  "618,善化農會",
  "618,新營農會",
  "618,楠西農會",
  "618,歸仁農會",
  "619,大社農會",
  "619,大寮農會",
  "619,大樹農會",
  "619,小港農會",
  "619,仁武農會",
  "619,內門農會",
  "619,六龜農會",
  "619,永安農會",
  "619,田寮農會",
  "619,甲仙農會",
  "619,杉林農會",
  "619,岡山農會",
  "619,林園農會",
  "619,阿蓮農會",
  "619,美濃農會",
  "619,茄萣農會",
  "619,梓官農會",
  "619,鳥松農會",
  "619,湖內農會",
  "619,路竹農會",
  "619,旗山農會",
  "619,鳳山農會",
  "619,橋頭農會",
  "619,燕巢農會",
  "619,彌陀農會",
  "620,九如農會",
  "620,竹田農會",
  "620,車城農會",
  "620,里港農會",
  "620,佳冬農會",
  "620,東港農會",
  "620,枋山農會",
  "620,枋寮農會",
  "620,林邊農會",
  "620,長治農會",
  "620,南州農會",
  "620,屏東市農會",
  "620,屏東縣農會",
  "620,恆春農會",
  "620,崁頂農會",
  "620,琉球農會",
  "620,高樹農會",
  "620,新園農會",
  "620,萬丹農會",
  "620,萬巒農會",
  "620,滿州農會",
  "620,潮州農會",
  "621,吉安農會",
  "621,富里農會",
  "621,新秀農會",
  "621,壽豐農會",
  "622,太麻里農會",
  "622,台東農會",
  "622,成功農會",
  "622,池上農會",
  "622,東河農會",
  "622,長濱農會",
  "622,鹿野農會",
  "622,關山農會",
  "624,澎湖農會",
  "627,連江縣農會",
  "700,中華郵政",
  "803,聯邦銀行",
  "805,遠東銀行",
  "806,元大銀行",
  "807,永豐銀行",
  "808,玉山銀行",
  "809,凱基銀行",
  "810,星展(台灣)銀行",
  "812,台新銀行",
  "815,日盛銀行",
  "816,安泰銀行",
  "822,中國信託",
  "826,樂天銀行",
];
