<template>
  <div>
    <header>
      <h3 class="float-left">車牌快找工具 Dashboard</h3>
    </header>
    <v-container fluid>
      <v-row class="mb-0">
        <v-col cols="auto" align-self="center">
          - 更新時間: {{ updatedAt }}
        </v-col>
        <v-col cols="auto" align-self="center"> - 有效: {{ sum.valid }} </v-col>
        <v-col cols="auto" align-self="center"> - 暫停: {{ sum.issue }} </v-col>
        <v-col cols="auto" align-self="center">
          - 系統狀態:
          <v-icon :color="lastFetch ? 'green' : 'red'">
            mdi-circle
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-0">
        <v-col cols="auto">
          <v-select
            v-model="selected.station"
            :items="stations"
            item-value="name"
            item-text="name"
            label="單位"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-select
            v-model="selected.type"
            :items="carTypes"
            item-value="selPlateType"
            :item-text="
              (item) => {
                return `${mapType(item.selPlateType)} (${item.selPlateType})`;
              }
            "
            label="車牌別"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="filter = ''">顯示全部</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="info" @click="filter = 'valid'">顯示有效</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="info" @click="filter = 'issue'">顯示暫停</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="error" @click="restartVIPRenew">重啟更新</v-btn>
        </v-col>
      </v-row>

      <v-data-table
        v-if="table"
        :headers="headers"
        :items="filterList"
        class="elevation-1"
      >
        <template v-slot:item.carType.selPlateType="{ item }">
          <a href="#" @click="selected.type = item.carType.selPlateType"
            >{{ mapType(item.carType.selPlateType) }} ({{
              item.carType.selPlateType
            }})
          </a>
        </template>
        <template v-slot:item.queryType="{ item }">
          {{ item.queryType == "0" ? "一般" : "流標" }}
        </template>
        <template v-slot:item.station.name="{ item }">
          <a href="#" @click="selected.station = item.station.name">{{
            item.station.name
          }}</a>
        </template>
        <template v-slot:item.valid="{ item }">
          <v-icon :color="item.valid ? 'green' : 'red'">
            mdi-circle
          </v-icon>
        </template>
        <template v-slot:item.issue="{ item }">
          <v-checkbox
            v-model="item.issue"
            @click="toggle(item, 'issue')"
          ></v-checkbox>
        </template>
        <template v-slot:item.processing="{ item }" class="center">
          <v-checkbox
            v-model="item.processing"
            @click="toggle(item, 'processing')"
          ></v-checkbox>
          <div v-if="item.processing">{{ item.processingAt }}</div>
        </template>
        <template v-slot:item.force="{ item }">
          <v-checkbox
            v-model="item.force"
            @click="toggle(item, 'force')"
          ></v-checkbox>
        </template>
        <template v-slot:item.fetchTags="{ item }">
          {{ item.fetchTags || item.tags || 0 }} ({{ getTags(item) }})
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import axios from "axios";
import config from "@/libs/config.js";
import _ from "lodash";
import carTypes from "@/libs/carTypes.js";
import stations from "@/libs/stations.js";

const updateTime = 10;
const typeMapping = {
  1: "自用小客貨車",
  h: "電動租賃車",
  g: "電動自小客",
  // i: "電動小營客車",
  G: "紅牌重機車",
  F: "黃牌重機車",
  P: "電動普通重型機車",
  H: "普通重型機車",
  2: "租賃小客貨車",
  3: "營業小客車",
  6: "自用大貨車",
  Q: "電動普通輕型機車",
};

export default {
  name: "Dashboard",
  data: () => ({
    table: true,
    updatedAt: "",
    carTypes: ["全選", ...carTypes],
    stations: ["全選", ...stations],
    list: [],
    tags: [],
    selected: {
      station: null,
      type: null,
    },
    filter: "",
    headers: [
      { text: "監理單位", value: "station.name" },
      { text: "車牌別", value: "carType.selPlateType" },
      { text: "查詢別", value: "queryType" },
      { text: "狀態", value: "valid" },
      { text: "暫停", value: "issue" },
      { text: "手動更新", value: "processing" },
      { text: "手動抓取", value: "force" },
      { text: "Fetch Tags (DB)", value: "fetchTags" },
      { text: "全車牌抓取", value: "fetchAt" },
      { text: "新車牌數量", value: "touchTags" },
      { text: "新車排抓取", value: "touchAt" },
    ],
  }),
  computed: {
    lastFetch() {
      let list = this.list;
      list = list.filter((o) => o.valid);
      list = _.sortBy(list, ["fetchAt"]);

      if (!list) return false;

      list.reverse();
      let lastRecord = list[0]?.fetchAt;
      return dayjs().diff(lastRecord, "minute") < 20;
    },
    sum() {
      return {
        valid: this.list.filter((o) => o.valid).length + "/" + this.list.length,
        issue: this.list.filter((o) => o.issue).length + "/" + this.list.length,
        renew:
          this.list.filter((o) => o.processing).length + "/" + this.list.length,
      };
    },
    filterList() {
      let list = this.list;
      if (this.selected.station && this.selected.station != "全選") {
        list = list.filter((o) => o.station.name == this.selected.station);
      }
      if (this.selected.type && this.selected.type != "全選") {
        list = list.filter((o) => o.carType.selPlateType == this.selected.type);
      }

      switch (this.filter) {
        case "valid":
        case "processing":
        case "issue":
        case "force":
          list = list.filter((o) => o[this.filter]);
          break;
      }

      return list;
    },
  },
  async created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.updatedAt = dayjs().format("YYYY-MM-DD HH:mm:ss");
      let res = await axios.get(`${config.API_URL}/dashboard/station`);
      this.list = res.data.list;
      this.tags = res.data.tags;
      setTimeout(() => {
        this.fetch();
      }, updateTime * 1000);
    },
    mapType(type) {
      return typeMapping[type];
    },
    async restartVIPRenew() {
      if (confirm("確認重啟更新？")) {
        await axios.post(
          `${config.API_URL}/dashboard/command/restart/vip-renew`
        );
        this.$toast.success("重啟完成！");
      }
    },
    async toggle(row, key) {
      // this.table = false;
      // setTimeout(() => {
      //   this.table = true;
      // }, 0);
      await axios.post(
        `${config.API_URL}/dashboard/station/${row._id}`,
        _.pick(row, [key])
      );
    },
    getTags(item) {
      let finded = this.tags.find(
        (o) =>
          o._id.station == item.station.name &&
          o._id.type == item.carType.selPlateType
      );

      return finded?.tags || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background-color: #1976d2;
  color: #fff;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
</style>
